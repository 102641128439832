<div id="trendingVideo" class="container scrollbar pt-4" >
<div class="videoTile" *ngFor="let item of trendingVideos, let i=index" #videoContainer>
  <!--full screen Video Panel--> 
    <div id="videoContainer" class="fullScreenVideo" [class.fullScreen]="isFullscreen">
      <div id="fullScreenPanel" *ngIf="i==0" class="videoContainer" >
        <video id="fullScreenVideo" class="video-panel" [muted]="true" controls playsinline controlsList="nodownload noplaybackrate nofullscreen" disablePictureInPicture (ended)="videoEnds(activeVideoIndex)" #videoPlayer2>
          <source  type="video/mp4">
        </video>
        <img id="minimizeIcon_{{i}}" class="minimize-icon" [class.fullScreen]="isFullscreen"  (click)="exitFullScreen(i)" alt="minimize-icon"/>
        <div id="full_screen_desc" class="description" [class.fullScreen]="isFullscreen"></div>  
      </div>
    </div>
  <!--small screen video panel-->
    <div id="videoContainer" class="trendingVideo">
      <div id="video-panel_{{i}}" class="videoContainer" >
      <video id="tileVideo_{{i}}" class="video-panel" [muted]="true"  playsinline controlsList="nodownload noplaybackrate nofullscreen" disablePictureInPicture (ended)="videoEnds(i)" #videoPlayer>
        <source  type="video/mp4">
      </video>
      </div>
      <img id="videoIcon_{{i}}" class="play-icon" #videoIcon (click)="toggleVideo(i)" alt="play-Icon"/>
      <img id="expandIcon_{{i}}" class="expand-icon"  (click)="fullScreen(i)" alt="expand-icon"/>
    </div>
    <div class="video-desc">
      <p class="video_title" [innerHTML]="item.tr_specTitle"></p>
    </div>
    
  </div>  
</div>