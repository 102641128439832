import { Component,ElementRef,OnInit, ViewChildren, QueryList } from '@angular/core';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { HeaderDetails } from 'src/app/core/interfaces/library-v2-data.i';
import { GlobalConstants } from '../../../common/constants/global.constant';

@Component({
  selector: 'app-trending',
  templateUrl: './trending.component.html',
  styleUrls: ['./trending.component.css']
})

export class TrendingComponent implements OnInit {
  @ViewChildren('videoPlayer') videoPlayers!: QueryList<ElementRef<HTMLVideoElement>>;
  @ViewChildren('videoPlayer2') fullScreenVideoPlayer!: ElementRef<HTMLVideoElement>;
  @ViewChildren('videoContainer') videoContainers!: QueryList<ElementRef<HTMLElement>>;
  trendingVideos: HeaderDetails[];
  isFullscreen = false;
  previousVideo: any;
  previousIcon: any;
  previousExpandIcon: any;
  isFirstPlay: boolean[];
  scrollHeight: number[]=[];
  videoHeight: number = 0;
  fullScreenEnd: boolean;
  activeVideoIndex: number = 0;
  constructor(    
    private qrCodeService: QrCodeService,
    private libraryV2DataService: LibraryV2DataService
  ) { }

  ngOnInit() {
    this.trendingVideos = this.libraryV2DataService.getTrending();
    this.trendingVideos.forEach((item, index) => {
      item.targetURL = item.targetURL.replace(GlobalConstants.XX, this.qrCodeService.qrInput.Language);
    });
    this.isFirstPlay = new Array(this.trendingVideos.length).fill(false);    
    document.addEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));     
  }

  ngOnDestroy() {
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange.bind(this));
  }

  ngAfterViewInit() {
    this.trendingVideos.forEach((item, index) => {
      const videoplayer = document.getElementById('tileVideo_' + index) as HTMLVideoElement;
      videoplayer.setAttribute('src',item.targetURL);     
    }); 
  
    setTimeout(() => {
      this.toggleVideo(this.activeVideoIndex);      
    }, 500);     
      
  }

  handleFullscreenChange() {
    this.isFullscreen = !!document.fullscreenElement;
  }

  videoEnds(i: number) {
    const videoplayer = document.getElementById('tileVideo_' + i) as HTMLVideoElement;
    videoplayer.currentTime = 5;
    this.activeVideoIndex = (i + 1) % this.trendingVideos.length;   
    this.isFirstPlay[i]= false;
    if (this.isFullscreen) {
      this.fullScreenEnd= true;
      this.fullScreen(this.activeVideoIndex);            
    }
    else{
    this.toggleVideo(this.activeVideoIndex);
  }     
  }

  toggleVideo(i: number) {   
    this.activeVideoIndex= i; 
    const videoplayer = document.getElementById('tileVideo_' + i) as HTMLVideoElement;
    const videoIcon = document.getElementById('videoIcon_' + i) as HTMLElement;
    const expandIcon = document.getElementById('expandIcon_' + i) as HTMLElement;
    
    if(videoplayer.paused){
      if(this.previousVideo){
        this.previousVideo.pause();
        this.previousIcon.style.opacity='1';
        this.previousExpandIcon.style.display ='none';
      } 
      if(!this.isFirstPlay[i] || this.isFullscreen){
        this.isFirstPlay[i] = true;
        videoplayer.currentTime = 0;
      }     
      videoplayer.play();
      this.scrollToActiveVideo(i);  
      videoIcon.style.opacity='0';
      expandIcon.style.display = 'block';

    }else{
      videoplayer.pause();
      videoIcon.style.opacity='1';
      expandIcon.style.display='none';
    }
    this.previousVideo=videoplayer;
    this.previousIcon=videoIcon;
    this.previousExpandIcon=expandIcon;
  }

  fullScreen(id: number) {
    let video = document.getElementById('fullScreenVideo') as HTMLVideoElement;
    let title = document.getElementById('full_screen_desc') as HTMLElement;
    const desc = document.getElementById('fullScreenPanel');
    if (desc.requestFullscreen) {
      desc.requestFullscreen();
      video.src=this.trendingVideos[id].targetURL;
      title.innerHTML=this.trendingVideos[id].tr_specTitle;
      video.load();
      video.play();
      if(!this.fullScreenEnd) {
        this.toggleVideo(id);
      }     
    }
  }
  exitFullScreen(id :number) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    let video = document.getElementById('fullScreenVideo') as HTMLVideoElement;
    video.pause();
    this.toggleVideo(this.activeVideoIndex);    
    this.scrollToActiveVideo(this.activeVideoIndex);
    this.fullScreenEnd=false;
  }
  setInitialThumbnails() {
    this.videoPlayers.forEach((player, index) => {
      const video = player.nativeElement;
      video.currentTime = 5;
    });
  }

  scrollToActiveVideo(index: number) {   
    if(index===0){
      for(let i=0;i<this.trendingVideos.length;i++){
        const activeContainer = this.videoContainers.toArray()[i].nativeElement;
        this.scrollHeight[i] = activeContainer.getBoundingClientRect().top ;
        this.videoHeight = activeContainer.getBoundingClientRect().height;     
      }    
    }
    const offset = this.scrollHeight[index] - (window.innerHeight / 2) + (this.videoHeight) - 40;    
    const scroll= document.getElementById('trendingVideo')?.scrollTo({ top: offset, behavior: 'smooth' });
  }
  // scrolling(){
  //  for(let i=0;i<3;i++){
  //   if(this.loadVideoList.length< this.trendingVideos.length){
  //     this.loadVideoList.push(this.trendingVideos[i+this.loadVideoList.length]);
  //   }
  //  }
  // }
}

